'use strict';
jQuery(document).ready(function ($) {
  function load_js() {
    var head = document.getElementsByTagName('head')[0];
    var script = document.createElement('script');
    script.src = './assets/js/dist/script.min.js';
    head.appendChild(script);
  }

  $('#register_form')
    // to prevent form from submitting upon successful validation
    .on('submit', function (ev) {
      ev.preventDefault();
      $('#success-message').hide();
      $('#error-message').text('');
      $('#register_form button').attr('disabled', 'true');
      var that = $('#register_form'),
        url = that.attr('action'),
        method = that.attr('method'),
        data = {};

      that.find('[name]').each(function () {
        var that = $(this),
          name = that.attr('name'),
          value = that.val();
        data[name] = value;
      });

      $.ajax({
        url: url,
        type: method,
        data: data,
        success: function (response) {
          if (response == 'success') {
            $('#success-message').show();
            $('#register_form').hide();
          } else {
            $('#register_form button').attr('disabled', 'false');
            $('#error-message').text('Failed, please try again.');
          }
        },
        error: function (response) {
          $('#register_form button').attr('disabled', 'false');
          $('#error-message').text('Failed, please try again.');
          console.log(response);
        },
      });
    });

  //expanded
  $('#toggle-expanded').on('click', function () {
    $('#intro-section').addClass('hidden');
    $('#expanded-section').removeClass('hidden');
  });
});
